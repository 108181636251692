import React, { createRef } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Image, Dimensions, Platform, TouchableWithoutFeedback, FlatList, ActivityIndicator } from 'react-native'
import { AntDesign, MaterialIcons, Ionicons, FontAwesome, FontAwesome5, Feather, Entypo } from '@expo/vector-icons';
import { connect } from 'react-redux'
import CalendarPicker from 'react-native-calendar-picker';
import Moment from 'moment'
import Board from '../components/keyworad'
// import Class from './classification'
// import DishName from './dishName'
// import FlavorList from './flavor'
// import { changeLanguage } from '../../store/actions/translate';
// import { addOrder } from '../../store/actions/order'
// import { addMenu } from '../../store/actions/menu'
import { request } from '../until/request'
// import Content from './content'
class Test extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            itemsIndex: 0,
            menuList: [],
            feedingList: [],
            detailList: [],
            orderList: [],
            modifierList: [],
            flavorList: [],
            selectData: {},
            modifier: null,
            finnalTips: 0,
            subtotal: 0,
            finnalDiscount: 0,
            total: 0,
            OrderTypeVisible: false,
            TimeVisible: false,
            PriceVisible: false,
            SelfVisible: false,
            DiscountVisible: false,
            TipsVisible: false,
            discount: '',
            orderType: 'PICK_UP',
            tips: '',
            temPrice: '',
            QtyVisible: false,
            temQty: '',
            scheduleTime: '',
            selectHour: '',
            selectMinute: '',
            HourDropActive: false,
            MinuteDropActive: false,
            inputNameActive: false,
            inputQuantityActive: false,
            inputPriceActive: false,
            inputSelect: '',
            selfInputData: {
                name: '',
                price: '',
                quantity: ''
            },
            finnalScheduleTime: '',
            employee: this.props.employee,
            splickData: {},
            SuresplickVisible: false,
            sureLoading: false,
            SurePayVisible: false,
            buttonDisable: false,
        }
        this.number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '.', '%']
        this.number2 = [1, 2, 3, 4, 5, 6, 7, 8, 9]
        this.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        this.flatlistRef = createRef()
        this.shouwMinutes = this.shouwMinutes.bind(this)
        this.propsList = this.propsList.bind(this)
        this.computeOrderList = this.computeOrderList.bind(this)
        this.changeMenu = this.changeMenu.bind(this)
        this.determineModifier = this.determineModifier.bind(this)
        this.clickSelect = this.clickSelect.bind(this)
        this.determineOrder = this.determineOrder.bind(this)
        this.upSelect = this.upSelect.bind(this)
        this.downSelect = this.downSelect.bind(this)
        this.addQty = this.addQty.bind(this)
        this.reduceQty = this.reduceQty.bind(this)
        this.discountKeyboard = this.discountKeyboard.bind(this)
        this.changeOrderType = this.changeOrderType.bind(this)
        this.tipsKeyboard = this.tipsKeyboard.bind(this)
        this.priceKeyboard = this.priceKeyboard.bind(this)
        this.qtyKeyboard = this.qtyKeyboard.bind(this)
        this.changeVaule = this.changeVaule.bind(this)
        this.selfInputDataSave = this.selfInputDataSave.bind(this)
        this.finalOrderList = this.finalOrderList.bind(this)
        this.saveOrderList = this.saveOrderList.bind(this)
        this.gotoSplit = this.gotoSplit.bind(this)
        this.gotoPaymeny = this.gotoPaymeny.bind(this)
        this.submitTypeData = this.submitTypeData.bind(this)
    }
    shouwMinutes = () => {
        let results = []
        for (let index = 0; index < 60; index++) {
            if (index <= 9) {
                index = '0' + index
            }
            results.push(index)
        }
        return results.map((item, key) => {
            return (
                <TouchableOpacity style={{ marginVertical: 3, marginLeft: 3 }} key={key} onPress={() => { this.setState({ MinuteDropActive: false, selectMinute: item }) }} >
                    <Text>{item}</Text>
                </TouchableOpacity>
            )
        })
    }

    componentWillReceiveProps(nexPorps) {
        console.log('进入props')
        console.log(nexPorps)
        if (this.props.route.params.order) {

        } else {
            console.log('lalal')
            this.setState({
                // orderList: nexPorps.route.params.orderList.list,
                orderList: ['PICK_UP', ['DELIVERY']],
                // orderType: nexPorps.route.params.orderType,
                orderType: 'PICK_UP',
                finnalDiscount: nexPorps.route.params.orderList.discount,
                tips: nexPorps.route.params.orderList.tip,
                subtotal: nexPorps.route.params.orderList.subtotal,
                total: nexPorps.route.params.orderList.total,
                finnalScheduleTime: nexPorps.route.params.orderList.scheduleTime,
            })
        }
    }

    propsList = () => {
        if (this.props.route.params.order) {
            console.log('进入order')
            // this.setState({ splickData: this.props.route.params.order })
            var obj = this.props.route.params.order
            var list = obj.orderItems
            var results = []
            list.forEach((element, index) => {
                results.push(element)
                if (element.orderItemsOptions.length > 0) {
                    results.push(...element.orderItemsOptions)
                }
                element.orderItemsOptions = []
            });
            results.forEach((el, index) => {
                el.index = index
                if (index === 0) {
                    el.select = true
                }
            })
            this.setState({
                selectData: results[0],
                orderList: results,
                finnalDiscount: obj.discount,
                finnalTips: obj.tip,
                subtotal: obj.subTotal,
                total: obj.total,
                orderType: obj.tableName ? obj.orderType + '( ' + obj.tableName + ' )' : obj.orderType,
                finnalScheduleTime: obj.scheduleTime === null ? '' : obj.scheduleTime.substr(0, 10) + ' ' + obj.scheduleTime.substr(11, 8)
            })
        }
    }
    componentDidMount() {
        this.propsList()
        console.log('进入')
        const that = this;
        async function a() {
            let obj = {
                menuList: [],
                specialCategories: [],
                specialModifiers: [],
                detailList: [],
                flavorList: [],
                modifierList: [],
            }
            await request(`/secure/api/tapthru/getSpecialRequestCategories?restaurantInfoId=3`, {
                method: 'POST'
            }).then(respone => {
                return respone.json()
            })
                .then(responeText => {
                    obj.specialCategories = responeText;
                    that.setState({
                        feedingList: responeText
                    })
                })

            await request(`/secure/api/tapthru/getStoreMenu?restaurantInfoId=3`, {
                method: 'POST'
            }).then(respone => respone.json())
                .then(responeText => {
                    responeText.forEach((element, index) => {
                        if (index === 0) {
                            element.clickActive = true
                        } else {
                            element.clickActive = false
                        }
                    });
                    obj.menuList = responeText
                    that.setState({
                        menuList: responeText,
                        detailList: responeText[0].items,
                        flavorList: responeText[0].categoryOptionGroups
                    })
                })
            await request(`/secure/api/tapthru/getSpecialRequestModifiers?restaurantInfoId=3`, {
                method: 'POST'
            }).then(respone => respone.json())
                .then(responeText => {
                    obj.specialModifiers = responeText
                    that.setState({
                        modifierList: responeText
                    })
                })
        }
        a()

    }
    computeOrderList = (list) => {
        var subtotal = 0
        var total = 0
        list.forEach(element => {
            subtotal = subtotal + (element.quantity * element.price)
        });
        total = subtotal - this.state.finnalDiscount + this.state.finnalTips + subtotal * 0.08
        this.setState({ total: total, subtotal: subtotal })
    }
    changeMenu = (key) => {
        var list = this.state.menuList
        list.forEach((element, index) => {
            if (key === index) {
                element.clickActive = true
            } else {
                element.clickActive = false
            }
        });
        var items = list[key].items
        var groups = list[key].categoryOptionGroups
        this.setState({
            detailList: items,
            flavorList: groups,
            menuList: [...list]
        })
    }
    showMenuItem = (item) => {
        var list = item.specialRequestMaterials
        var menu = this.state.menuList
        menu.forEach((element, index) => {
            element.clickActive = false
        });
        this.setState({
            detailList: [...list],
            flavorList: this.state.modifierList,
            menuList: [...menu]
        })
    }
    determineModifier = (obj) => {
        this.setState({ modifier: obj })
    }
    clickSelect = (obj) => {
        var op = obj
        op.select = true
        this.setState({ selectData: op })
        var list = this.state.orderList
        list.forEach((el, index) => {
            if (el.index === obj.index) {
                el.select = true
                console.log(189)
            } else {
                el.select = false
            }
        })
        this.setState({ orderList: [...list] })
    }
    determineOrder = (obj) => {
        let clone = JSON.parse(JSON.stringify(obj))
        let list = this.state.orderList
        if (clone.itemId != undefined) {
            clone.index = this.state.itemsIndex
            clone.quantity = 1
            clone.select = true
            clone['orderItemsOptions'] = []
            this.setState({ selectData: clone })
            list.forEach(element => { element.select = false })
            list.push(clone)
            this.setState({ orderList: [...list], itemsIndex: this.state.itemsIndex + 1 })
            this.computeOrderList(list)
            // setTimeout(() => {
            //     this.flatlistRef.current.scrollToEnd({ animated: true })
            // }, 200);
        } else {
            var key = null
            var select = this.state.selectData
            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                if (element.index === select.index) {
                    key = i
                    break;
                }
            }
            if (this.state.modifier === null) {
                clone.index = this.state.itemsIndex
                clone.quantity = 1
                clone.select = true
                this.setState({ selectData: clone })
                list.forEach(element => { element.select = false })
                list.splice(key + 1, 0, clone)
                console.log(key)
                console.log(clone)
                this.setState({ orderList: [...list], itemsIndex: this.state.itemsIndex + 1 })
                this.computeOrderList(list)
                setTimeout(() => {
                    this.flatlistRef.current.scrollToIndex({ index: key, animated: true })
                }, 200);
            } else {
                var p = {
                    name: this.state.modifier.name + ' ' + clone.name,
                    cnName: this.state.modifier.cnName + clone.cnName,
                    quantity: 1,
                    price: clone.price,
                    index: this.state.itemsIndex,
                    select: true
                }
                this.setState({ selectData: p, modifier: null })
                list.forEach(element => { element.select = false })
                list.splice(key + 1, 0, p)
                this.setState({ orderList: [...list], itemsIndex: this.state.itemsIndex + 1 })
                this.computeOrderList(list)
                setTimeout(() => {
                    this.flatlistRef.current.scrollToIndex({ index: key, animated: true })
                }, 200);
            }
        }
    }
    upSelect = () => {
        if (this.state.orderList.length !== 0) {
            var list = this.state.orderList
            var key = null
            for (var i = 0; i < list.length; i++) {
                if (list[i].index === this.state.selectData.index) {
                    key = i
                    break;
                }
            }
            if (key - 1 >= 0) {
                list[key].select = false
                list[key - 1].select = true
                this.flatlistRef.current.scrollToIndex({ index: key - 1, animated: true, viewPosition: 0.5 })
                this.setState({
                    selectData: list[key - 1],
                    orderList: [...list]
                })
            }
        }
    }
    downSelect = () => {
        if (JSON.stringify(this.state.selectData) !== '{}') {
            if (this.state.orderList.length !== 0) {
                var list = this.state.orderList
                var key = null
                for (var i = 0; i < list.length; i++) {
                    if (list[i].index === this.state.selectData.index) {
                        key = i
                        break;
                    }
                }
                console.log(key)
                if (key !== list.length - 1) {
                    list[key].select = false
                    list[key + 1].select = true
                    this.flatlistRef.current.scrollToIndex({ index: key + 1, animated: true, viewPosition: 0.5 })
                    this.setState({
                        selectData: list[key + 1],
                        orderList: [...list]
                    })
                }
            }
        }

    }
    addQty = () => {
        if (JSON.stringify(this.state.selectData) != '{}') {
            var list = this.state.orderList
            var key = null
            for (var i = 0; i < list.length; i++) {
                if (list[i].id === this.state.selectData.id) {
                    key = i
                    break;
                }
            }
            list[key].quantity = list[key].quantity + 1
            list[key].subtotal = list[key].quantity * list[key].price
            this.setState({ orderList: [...list] })
            this.computeOrderList(list)
        }
    }

    reduceQty = () => {
        if (JSON.stringify(this.state.selectData) != '{}') {
            var list = this.state.orderList
            var key = null
            for (var i = 0; i < list.length; i++) {
                if (list[i].id === this.state.selectData.id) {
                    key = i
                    break;
                }
            }
            if (list[key].quantity <= 1) {
                list.splice(key, 1)
                list.forEach(element => {
                    element.select = false
                });
                this.setState({ selectData: {}, orderList: [...list] })
                this.computeOrderList(list)
            } else {
                list[key].quantity = list[key].quantity - 1
                list[key].subtotal = list[key].quantity * list[key].price
                this.setState({ orderList: [...list] })
                this.computeOrderList(list)
            }
        }
    }

    discountKeyboard = (keyboard) => {
        if (keyboard === 'delete') {
            var p = this.state.discount.toString()
            var data = p.substr(0, p.length - 1)
            this.setState({ discount: data })
        } else if (keyboard === 'ok') {
            if (this.state.discount.indexOf('%') === -1) {
                var op = this.state.discount === '' ? 0 : this.state.discount
                var p = this.state.finnalDiscount
                this.setState({
                    DiscountVisible: false,
                    finnalDiscount: parseFloat(op),
                    total: this.state.total + parseFloat(p) - parseFloat(op),
                    buttonDisable: false
                })
            } else {
                var op = this.state.discount.substr(0, this.state.discount.length - 1)
                var p = this.state.finnalDiscount
                this.setState({
                    DiscountVisible: false,
                    finnalDiscount: this.state.subtotal * parseFloat(op) * 0.01,
                    total: this.state.total + parseFloat(p) - this.state.subtotal * parseFloat(op) * 0.01,
                    buttonDisable: false
                })
            }

        } else {
            this.setState({
                discount: this.state.discount.toString() + keyboard.toString()
            })
        }
    }

    changeOrderType = (type) => {
        switch (type) {
            case 'WALK_IN':
                this.setState({ orderType: 'WALK_IN', OrderTypeVisible: false, buttonDisable: false })
                break;
            case 'DINE_IN':
            case 'BUFFET':
                this.props.navigation.navigate({
                    name: 'selectTable',
                    params: {
                        orderList: {
                            list: this.state.orderList,
                            tip: this.state.finnalTips,
                            discount: this.state.finnalDiscount,
                            subtotal: this.state.subtotal,
                            total: this.state.total,
                            scheduleTime: this.state.finnalScheduleTime
                        },
                        orderType: type
                    }
                })
                this.setState({ OrderTypeVisible: false, buttonDisable: false })
                break;
            case 'PICK_UP':
            case 'DELIVERY':
                this.props.navigation.navigate({
                    name: 'writeAddress',
                    params: {
                        orderList: {
                            list: this.state.orderList,
                            tip: this.state.finnalTips,
                            discount: this.state.finnalDiscount,
                            subtotal: this.state.subtotal,
                            total: this.state.total,
                            scheduleTime: this.state.finnalScheduleTime
                        },
                        orderType: type
                    }
                })
                this.setState({ OrderTypeVisible: false, buttonDisable: false })
                break;
            default:
                break;
        }
        this.setState({ OrderTypeVisible: false, buttonDisable: false })
    }

    tipsKeyboard = (keyboard) => {
        if (keyboard === 'delete') {
            var p = this.state.tips.toString()
            var data = p.substr(0, p.length - 1)
            this.setState({
                tips: data
            })
        } else if (keyboard === 'ok') {
            if (this.state.tips.indexOf('%') === -1) {
                var op = this.state.tips === '' ? 0 : this.state.tips
                var po = this.state.finnalTips
                this.setState({
                    TipsVisible: false,
                    finnalTips: parseFloat(op),
                    total: this.state.total - parseFloat(po) + parseFloat(op),
                    buttonDisable: false
                })
            } else {
                var op = this.state.tips.substr(0, this.state.tips.length - 1)
                var po = this.state.finnalTips
                this.setState({
                    TipsVisible: false,
                    finnalTips: this.state.subtotal * parseFloat(op) * 0.01,
                    total: this.state.total - parseFloat(po) + this.state.subtotal * parseFloat(op) * 0.01,
                    buttonDisable: false
                })
            }

        } else {
            this.setState({ tips: this.state.tips.toString() + keyboard.toString() })
        }
    }

    priceKeyboard = (keyboard) => {
        if (keyboard === 'delete') {
            var p = this.state.temPrice.toString()
            var data = p.toString().substr(0, p.toString().length - 1)
            this.setState({
                temPrice: data
            })
        } else if (keyboard === 'ok') {
            var list = this.state.orderList
            var key = null
            for (let i = 0; i < list.length; i++) {
                if (list[i].id === this.state.selectData.id) {
                    key = i
                    break
                }
            }
            console.log(key)
            list[key].price = parseFloat(this.state.temPrice).toFixed(2)
            list[key].subtotal = list[key].price * list[key].quantity
            console.log(list)
            this.setState({
                orderList: [...list],
                PriceVisible: false,
                selectData: list[key],
                buttonDisable: false
            })
            this.computeOrderList(list)
        } else {
            this.setState({
                temPrice: this.state.temPrice.toString() + keyboard.toString()
            })
        }
    }

    qtyKeyboard = (keyboard) => {
        if (keyboard === 'delete') {
            var p = this.state.selectData.quantity
            var data = p.toString().substr(0, p.toString().length - 1)
            this.setState({ temQty: data })
        } else if (keyboard === 'ok') {
            var list = this.state.orderList
            var key = null
            for (let i = 0; i < list.length; i++) {
                if (list[i].id === this.state.selectData.id) {
                    key = i
                    break
                }
            }
            if (this.state.temQty === '0') {
                list.splice(key, 1)
            } else {
                list[key].quantity = parseInt(this.state.temQty)
                list[key].subtotal = list[key].price * list[key].quantity
            }
            this.setState({
                orderList: [...list],
                QtyVisible: false,
                buttonDisable: false
            })
            this.computeOrderList(list)
        } else {
            this.setState({ temQty: this.state.temQty.toString() + keyboard.toString() })
        }
    }

    changeVaule = (newVaule, selection) => {
        switch (selection) {
            case 'InName':
                if (newVaule === 'delete') {
                    var data = this.state.selfInputData.name
                    this.setState({ selfInputData: { ...this.state.selfInputData, name: data.substr(0, data.length - 1) } })
                } else if (newVaule !== 'enter') {
                    this.setState({ selfInputData: { ...this.state.selfInputData, name: this.state.selfInputData.name + newVaule } })
                } else {
                    this.setState({ inputNameActive: false })
                }
                break;
            case 'InPrice':
                if (newVaule === 'delete') {
                    var data = this.state.selfInputData.price
                    this.setState({ selfInputData: { ...this.state.selfInputData, price: data.substr(0, data.length - 1) } })
                } else if (newVaule !== 'enter') {
                    this.setState({ selfInputData: { ...this.state.selfInputData, price: this.state.selfInputData.price + newVaule } })
                } else {
                    this.setState({ inputPriceActive: false })
                }
                break;
            case 'InQuantity':
                if (newVaule === 'delete') {
                    var data = this.state.selfInputData.quantity
                    this.setState({ selfInputData: { ...this.state.selfInputData, quantity: data.substr(0, data.length - 1) } })
                } else if (newVaule !== 'enter') {
                    this.setState({ selfInputData: { ...this.state.selfInputData, quantity: this.state.selfInputData.quantity + newVaule } })
                } else {
                    this.setState({ inputQuantityActive: false })
                }
                break;
            default:
                break;
        }
    }

    gotoSplit = () => {
        this.setState({ sureLoading: true })
        var data = this.submitTypeData()
        console.log(data)

        request('/secure/api/tapthru/saveOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `orderjson=${encodeURIComponent(JSON.stringify(data))}`
        }).then(respone => respone.json())
            .then(responeText => {
                if (this.state.orderType.split('(')[0] === 'DINE_IN' || this.state.orderType.split('(')[0] === 'BUFFET') {
                    var obj = this.props.route.params.tableName
                    obj.seated = true
                    obj.orderId = responeText.orderId
                    request(`/secure/api/tapthru/saveTable`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: `tablejson=${JSON.stringify(obj)}`
                    }).then(respone1 => respone1.json())
                }
                this.setState({ SuresplickVisible: false, buttonDisable: false })
                this.props.navigation.navigate({
                    name: 'split',
                    params: {
                        orderDetail: responeText
                    }
                })
                this.setState({ sureLoading: false })
            })
    }

    selfInputDataSave = () => {
        var list = this.state.orderList
        var prints = []
        list.forEach(element => {
            element.select = false
        });
        if (this.state.selfInputData.TReceipt) {
            prints.push('"TReceipt"')
        }
        if (this.state.selfInputData.Tkitchen) {
            prints.push('"Tkitchen"')
        }
        if (this.state.selfInputData.TSushi) {
            prints.push('"TSushi"')
        }
        var data = {
            name: this.state.selfInputData.name,
            cnName: this.state.selfInputData.name,
            price: parseFloat(this.state.selfInputData.price),
            quantity: parseInt(this.state.selfInputData.quantity),
            select: true,
            index: this.state.itemsIndex,
            printerName: '[' + prints.toString() + ']',
            itemId: '896523',
            orderItemsOptions: [],
            restaurantInfoId: 3,
            categoryId: 41852,
            categoryName: 'selfInput',
            categoryCnName: 'selfInput',
            deleted: null,
            employee: null,
            taxExempt: false,
            printable: true
        }
        list.push(data)
        console.log(data)
        console.log(prints)
        this.computeOrderList(list)
        this.setState({
            orderList: [...list],
            selectData: data,
            SelfVisible: false,
            itemsIndex: this.state.itemsIndex + 1,
            buttonDisable: false
        })
    }

    finalOrderList = (list) => {
        var temList = []
        var p = [] // 主菜的 索引值
        var final = []
        list.forEach((element, index) => {
            if (element.itemId != undefined) {
                p.push(index)
            }
        })
        for (let i = 0; i < p.length; i++) {
            temList.push(list.slice(p[i], p[i + 1]))
        }
        temList.forEach(element => {
            final.push(element[0])
        })
        for (let j = 0; j < temList.length; j++) {
            for (let k = 1; k < temList[j].length; k++) {
                temList[j][k].id = null
                temList[j][k].deleted = false
                final[j].orderItemsOptions.push(temList[j][k])
            }
        }
        final.forEach(element => {
            if (element.orderItemsOptions.length > 0) {
                let opo = 0
                for (let a = 0; a < element.orderItemsOptions.length; a++) {
                    var lo = element.orderItemsOptions[a].quantity * element.orderItemsOptions[a].price
                    opo = opo + lo
                }
                element.subtotal = opo + element.quantity * element.price
            } else {
                element.subtotal = element.quantity * element.price
            }
            element.mOrderItem = null
            element.checkId = element.checkId ? element.checkId : null
            element.specialInstructions = null
            element.orderItemType = null
            element.taxRate = null
            element.morderItem = null
            element.id = this.props.route.params.edit ? element.id : null
            // element.id = null
            element.orderItemsId = element.orderItemsId ? element.orderItemsId : null
            delete element.optionsList
        });
        return final
    }


    saveOrderList = () => {
        let data = this.submitTypeData()
        console.log(JSON.stringify(data))
        console.log(data)

        // this.props.addOrder(data)
        request('/secure/api/tapthru/saveOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `orderjson=${encodeURIComponent(JSON.stringify(data))}`
        }).then(respone => respone.json())
            .then(responeText => {
                this.setState({
                    orderList: [],
                    selectData: {},
                    modifier: null,
                    finnalTips: 0,
                    subtotal: 0,
                    finnalDiscount: 0,
                    total: 0,
                    discount: '',
                    tips: '',
                    temPrice: '',
                    temQty: '',
                    scheduleTime: '',
                    selectHour: '',
                    selectMinute: '',
                    inputSelect: '',
                    selfInputData: {
                        name: '',
                        price: '',
                        quantity: ''
                    },
                    finnalScheduleTime: '',
                })
                if (this.state.orderType.split('(')[0] === 'DINE_IN' || this.state.orderType.split('(')[0] === 'BUFFET') {
                    if (!this.props.route.params.edit) {
                        console.log(2)
                        var obj = this.props.route.params.tableName
                        obj.seated = true
                        obj.orderId = responeText.orderId
                        request(`/secure/api/tapthru/saveTable`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: `tablejson=${JSON.stringify(obj)}`
                        }).then(respone1 => respone1.json())
                    }
                }
            })
            .catch(error => console.log(error))
    }

    gotoPaymeny = () => {
        this.setState({ sureLoading: true })
        var data = this.submitTypeData()
        console.log(data)

        request('/secure/api/tapthru/saveOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `orderjson=${encodeURIComponent(JSON.stringify(data))}`
        }).then(respone => respone.json())
            .then(responeText => {
                if (this.state.orderType.split('(')[0] === 'DINE_IN' || this.state.orderType.split('(')[0] === 'BUFFET') {
                    var obj = this.props.route.params.tableName
                    obj.seated = true
                    obj.orderId = responeText.orderId
                    request(`/secure/api/tapthru/saveTable`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: `tablejson=${JSON.stringify(obj)}`
                    }).then(respone1 => respone1.json())
                }
                this.setState({ SuresplickVisible: false, buttonDisable: false })
                this.props.navigation.navigate({
                    name: 'pay',
                    params: {
                        order: responeText
                    }
                })
                this.setState({ sureLoading: false })
            })
    }

    submitTypeData = () => {
        let data = {}
        if (!this.props.route.params.edit) {
            data = {
                restaurantInfoId: 3,
                subTotal: this.state.subtotal,
                total: this.state.total,
                orderType: this.state.orderType.split('(')[0],
                customer: this.state.orderType.split('(')[0] === 'PICK_UP' || this.state.orderType.split('(')[0] === 'DELIVERY' ? this.props.route.params.customer : null, // 顾客信息 
                orderItems: this.finalOrderList(this.state.orderList),
                checks: this.props.route.params.checkList ? this.props.route.params.checkList : [], // 小票 分单用的到
                tableName: this.state.orderType.split('(')[0] === 'DINE_IN' || this.state.orderType.split('(')[0] === 'BUFFET' ? this.props.route.params.tableName.name : null, // 餐桌
                discount: this.state.finnalDiscount,
                deliveryFee: 0,
                convenienceFee: 0,
                tip: this.state.finnalTips,
                tax: this.state.subtotal * 0.08,
                employee: this.state.employee,
                scheduleTime: this.state.finnalScheduleTime === '' ? null : Moment(this.state.finnalScheduleTime).format('YYYY-MM-DDThh:mm:ss'),
                specialInstructions: null,
                mOrder: null,
                online: false,
                splitEvent: false,
                driverEmployee: null,
                taxRate: 0.08,
                refundAmount: null, // 退款金额
                voided: false,  // 有无效
                extraCharge: null, // 服务费
                orderPaymentMethod: null, // 付款方式
                sendToPostmates: false, // 发给postmates
                orderVoided: false, // 订单作废
                // balanceRemaining: this.state.total, // 未付款余额
            }
        } else {
            data = this.props.route.params.order
            data.subTotal = this.state.subtotal
            data.total = this.state.total
            data.orderType = this.state.orderType.split('(')[0]
            data.customer = this.state.orderType.split('(')[0] === 'PICK_UP' || this.state.orderType.split('(')[0] === 'DELIVERY' ? this.props.route.params.customer : null // 顾客信息 
            data.orderItems = this.finalOrderList(this.state.orderList)
            data.tableName = this.state.orderType.split('(')[0] === 'DINE_IN' || this.state.orderType.split('(')[0] === 'BUFFET' ? this.props.route.params.tableName.name : null // 餐桌
            data.discount = this.state.finnalDiscount
            data.tip = this.state.finnalTips
            data.tax = this.state.subtotal * 0.08
            data.balanceRemaining = this.state.total
            data.scheduleTime = this.state.finnalScheduleTime === '' ? null : Moment(this.state.finnalScheduleTime).format('YYYY-MM-DDThh:mm:ss')
        }
        return data
    }

    render() {
        return (
            // <ScrollView>
            <View style={styles.container} >
                <View style={{ flexDirection: 'row', width: width < 1024 ? 857 : 900, justifyContent: 'space-between', marginBottom: 15 }}>
                    <Text style={[styles.MeFont, { fontSize: width <= 900 ? 14 : 16 }]} >{this.state.orderType}</Text>
                    <Text style={[styles.MeFont, { fontSize: width <= 900 ? 14 : 16, display: this.state.finnalScheduleTime.length === 0 ? 'none' : 'flex' }]}>Schedule:Date/Time: {Moment(this.state.finnalScheduleTime).format('YYYY-MM-DD hh:mm:ss')}</Text>
                </View>
                <View style={{ flexDirection: 'row' }} >
                    {/* 分类 */}
                    <View>
                        <View>
                            <ScrollView showsVerticalScrollIndicator={false} style={{ height: width < 1024 ? 425 : 507.76 }}  >
                                <View style={{ width: width < 1024 ? 144 : 157.6, flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start' }}>
                                    {
                                        this.state.menuList.map((item, index) => {
                                            return (
                                                <TouchableOpacity key={index} style={[styles.blockStyle, item.clickActive ? styles.active : {}]} onPress={() => this.changeMenu(index)} disabled={this.state.buttonDisable} >
                                                    <Text style={{ fontFamily: 'TTFMedium', fontSize: width < 1024 ? 13 : 13.5, textAlign: 'center' }} >{this.props.translate.translate ? item.cnName : item.name}</Text>
                                                </TouchableOpacity>
                                            )
                                        })
                                    }
                                </View>
                            </ScrollView>
                        </View>

                        <ScrollView style={{ height: width < 1024 ? 146 : 147.34 }} showsVerticalScrollIndicator={false}>
                            <View style={{ flexDirection: 'row', width: width < 1024 ? 144 : 157.6, flexWrap: 'wrap', marginTop: 8, alignContent: 'flex-start' }}>
                                {
                                    this.state.feedingList.map((item, index) => {
                                        return (
                                            <TouchableOpacity style={[styles.blockStyle, { backgroundColor: '#CCCCFF', borderRadius: 5 }]} key={index} onPress={() => this.showMenuItem(item)} disabled={this.state.buttonDisable}>
                                                <Text style={styles.MeFont} >{this.props.translate.translate ? item.cnName : item.name}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </View>
                        </ScrollView>
                    </View>
                    {/* 菜名 */}
                    <View>
                        <ScrollView showsVerticalScrollIndicator={false} style={{ height: width < 1024 ? 577 : 662.12 }} >
                            <View style={{ width: width < 1024 ? 253 : 273, marginLeft: 5, flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start', }}>
                                {
                                    this.state.detailList.map((item, index) => {
                                        return (
                                            <TouchableOpacity style={[styles.dishNameStyle]} onPress={() => this.determineOrder(item)} key={index} disabled={this.state.buttonDisable}>
                                                <Text style={{ fontFamily: 'TTFMedium', fontSize: width < 1024 ? 13 : 13.5, textAlign: 'center' }} numberOfLines={3} >
                                                    {this.props.translate.translate ? item.cnName : item.name}
                                                </Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </View>
                        </ScrollView>
                    </View>
                    {/* 酸甜度 */}
                    <View style={{ marginLeft: 5, width: width < 1024 ? 72 : 81.92, height: width < 1024 ? 571 : 662.12 }}>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            {
                                this.state.flavorList.map((flavor, index) => {
                                    return (
                                        <View key={index}>
                                            {
                                                flavor.options ?
                                                    // 跟白饭 ......
                                                    <View>
                                                        {
                                                            flavor.options.map((item, key) => {
                                                                return (
                                                                    <TouchableOpacity style={[styles.flavorItemStyle, { backgroundColor: '#99CCCC' }]} key={key} onPress={() => this.determineOrder(item)} disabled={this.state.buttonDisable}>
                                                                        <Text style={styles.MeFont} >{this.props.translate.translate ? item.cnName : item.name}</Text>
                                                                    </TouchableOpacity>
                                                                )
                                                            })
                                                        }
                                                    </View>
                                                    :
                                                    // no less
                                                    <View>
                                                        <TouchableOpacity style={[styles.flavorItemStyle, { backgroundColor: '#99CCCC' }]} onPress={() => this.determineModifier(flavor)} disabled={this.state.buttonDisable}>
                                                            <Text style={styles.MeFont} >{this.props.translate.translate ? flavor.cnName : flavor.name}</Text>
                                                        </TouchableOpacity>
                                                    </View>
                                            }
                                        </View>
                                    )
                                })
                            }
                        </ScrollView>
                    </View>
                    <View style={{ marginHorizontal: width < 1024 ? 13 : 20 }} >
                        {/* 已点菜列表 */}
                        <View style={{ width: width < 1024 ? 280 : 300, height: width < 1024 ? 297 : 331.06, backgroundColor: '#fff', borderWidth: 2, borderColor: '#999999' }} >
                            {/* 表头 */}
                            <View style={{ backgroundColor: 'rgb(236,236,236)', flexDirection: 'row', borderBottomWidth: 1 }} >
                                <View style={{ alignItems: 'center', width: width < 1024 ? 45 : 50, height: 22, justifyContent: 'center' }} >
                                    <Text style={[styles.ReFont]}>{this.props.translate.translate ? '数量' : 'Qty'}</Text>
                                </View>
                                <View style={{ alignItems: 'center', width: width < 1024 ? 175 : 190, height: 22, justifyContent: 'center', borderColor: '#003366', borderRightWidth: 1, borderLeftWidth: 1 }} >
                                    <Text style={styles.ReFont}>{this.props.translate.translate ? '餐品' : 'Item'}</Text>
                                </View>
                                <View style={{ alignItems: 'center', width: width < 1024 ? 60 : 60, height: 22, justifyContent: 'center' }}>
                                    <Text style={styles.ReFont}>{this.props.translate.translate ? '小计' : 'Subtotal'}</Text>
                                </View>
                            </View>
                            {/* 内容 */}
                            <FlatList
                                data={this.state.orderList}
                                ref={this.flatlistRef}
                                renderItem={item => <Content data={item} clickSelect={this.clickSelect} />}
                                keyExtractor={(item, index) => item.name + item.id + index.toString()}
                                showsVerticalScrollIndicator={false}
                                onScrollToIndexFailed={info => {
                                    const wait = new Promise(resolve => setTimeout(resolve, 500));
                                    wait.then(() => {
                                        this.flatlistRef.current.scrollToIndex({ index: info.index, animated: true });
                                    });
                                }}
                            />
                        </View>
                        {/* 统计 */}
                        <View style={{ height: width < 1024 ? 280 : 331.06, justifyContent: 'flex-end' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-end' }} >
                                <View style={{ alignItems: 'flex-end' }} >
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }} >
                                        <Text style={[styles.MeFont, { fontSize: width < 1024 ? 14 : 15 }]} >{this.props.translate.translate ? '小费:' : 'Tips:'}</Text>
                                        <View style={styles.smallBlockStyle}>
                                            <Text style={[styles.ReFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.state.finnalTips.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 10 }} >
                                        <TouchableOpacity style={styles.upDownStyle} onPress={this.upSelect} disabled={this.state.buttonDisable} >
                                            <AntDesign name="caretup" size={width < 1024 ? 18 : 20} color="black" />
                                            <Text style={styles.MeFont} >{this.props.translate.translate ? '向上' : 'Up'}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={[styles.upDownStyle, { marginLeft: 15 }]} onPress={this.downSelect} disabled={this.state.buttonDisable} >
                                            <AntDesign name="caretdown" size={width < 1024 ? 18 : 20} color="black" />
                                            <Text style={styles.MeFont} >{this.props.translate.translate ? '向下' : 'Down'}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <View style={{ justifyContent: 'space-around', alignItems: 'flex-end' }} >
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 3 }} >
                                        <Text style={[styles.MeFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.props.translate.translate ? '小计:' : 'Subtotal:'}</Text>
                                        <View style={styles.smallBlockStyle}>
                                            <Text style={[styles.ReFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.state.subtotal.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 3 }} >
                                        <Text style={[styles.MeFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.props.translate.translate ? '折扣' : 'Discount'}</Text>
                                        <View style={styles.smallBlockStyle}>
                                            <Text style={[styles.ReFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.state.finnalDiscount.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 3 }} >
                                        <Text style={[styles.MeFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.props.translate.translate ? '税:' : 'Tax:'}</Text>
                                        <View style={styles.smallBlockStyle}>
                                            <Text style={[styles.ReFont, { fontSize: width < 1024 ? 15 : 15 }]} >{(this.state.subtotal * 0.08).toFixed(2)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 3 }} >
                                        <Text style={[styles.MeFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.props.translate.translate ? '总价:' : 'Total:'}</Text>
                                        <View style={styles.smallBlockStyle}>
                                            <Text style={[styles.ReFont, { fontSize: width < 1024 ? 15 : 15 }]} >{this.state.total.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View>
                                <View style={{ flexDirection: 'row', marginTop: 20 }} >
                                    <TouchableOpacity style={[styles.otherType, { backgroundColor: '#99CCFF' }]} onPress={this.reduceQty} disabled={this.state.buttonDisable}>
                                        <Text style={styles.ReFont} >{this.props.translate.translate ? '减少' : 'Reduce'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.otherType, { backgroundColor: '#99CCFF' }]} onPress={this.addQty} disabled={this.state.buttonDisable} >
                                        <Text style={styles.ReFont} >{this.props.translate.translate ? '增加' : 'Add'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.otherType, { backgroundColor: '#99CCFF' }]} onPress={() => this.setState({ QtyVisible: true, temQty: this.state.selectData.quantity, buttonDisable: true })} disabled={this.state.buttonDisable}>
                                        <Text style={styles.ReFont} >{this.props.translate.translate ? '数量' : 'Qty'}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 5 }} >
                                    <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ SurePayVisible: true })} disabled={this.state.buttonDisable} >
                                        <MaterialIcons name="payment" size={width < 1024 ? 20 : 20} color="black" />
                                        <Text style={styles.ReFont} >{this.props.translate.translate ? '付款' : 'Payment'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={this.saveOrderList} disabled={this.state.buttonDisable}>
                                        <AntDesign name="save" size={width < 1024 ? 20 : 20} color="black" />
                                        <Text style={styles.ReFont} >{this.props.translate.translate ? '保存' : 'Save'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} disabled={this.state.buttonDisable} >
                                        <AntDesign name="printer" size={width < 1024 ? 20 : 20} color="black" />
                                        <Text style={styles.ReFont} >{this.props.translate.translate ? '打印' : 'Print'}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View >
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.props.changeLanguage()} disabled={this.state.buttonDisable}>
                            <AntDesign name="earth" size={width < 1024 ? 18 : 23} color="black" />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? 'English' : '中文'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ OrderTypeVisible: true, buttonDisable: true })} disabled={this.state.buttonDisable}>
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '订单类型' : 'Order Type'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ TimeVisible: true, buttonDisable: true })} disabled={this.state.buttonDisable} >
                            <Ionicons name="md-timer" size={width < 1024 ? 18 : 23} color="black" />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '设置时间' : 'Schedule Time'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ PriceVisible: true, temPrice: this.state.selectData.price, buttonDisable: true })} disabled={this.state.buttonDisable} >
                            <FontAwesome name="dollar" size={width < 1024 ? 18 : 23} color="black" />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '修改价格' : 'Set Price'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ SelfVisible: true, buttonDisable: true })} disabled={this.state.buttonDisable}>
                            <FontAwesome5 name="list-ul" size={width < 1024 ? 18 : 23} color="black" />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '自定义输入' : 'Self Input'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ SuresplickVisible: true, buttonDisable: true })} disabled={this.state.buttonDisable} >
                            <MaterialIcons name="call-split" size={width < 1024 ? 18 : 23} color="black" />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '分单' : 'split Check'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ DiscountVisible: true, buttonDisable: true })} disabled={this.state.buttonDisable} >
                            <Image source={require('../assets/discount.png')} style={{ width: width < 1024 ? 18 : 23, height: width < 1024 ? 18 : 23 }} />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '折扣' : 'Discount'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#CCCCFF' }]} onPress={() => this.setState({ TipsVisible: true, buttonDisable: true })} disabled={this.state.buttonDisable} >
                            <Image source={require('../assets/tips.png')} style={{ width: width < 1024 ? 18 : 23, height: width < 1024 ? 18 : 23 }} />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '小费' : 'Tips'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.otherType, { backgroundColor: '#FF9999' }]} onPress={() => {
                            this.props.route.params.edit === true ? this.props.navigation.navigate({ name: 'orderList', params: { reLoading: true } })
                                : this.props.navigation.goBack()
                        }} disabled={this.state.buttonDisable} >
                            <MaterialIcons name="exit-to-app" size={width < 1024 ? 18 : 23} color="black" />
                            <Text style={styles.ReFont} >{this.props.translate.translate ? '退出' : 'Exit'}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {/* discount弹窗 */}
                <View style={[styles.modalStyle, { display: this.state.DiscountVisible ? 'flex' : 'none', position: this.state.DiscountVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ alignItems: 'flex-end', width: '100%' }} >
                        <TouchableOpacity onPress={() => this.setState({ DiscountVisible: false, buttonDisable: false })} >
                            <AntDesign name="close" size={width < 1024 ? 18 : 23} color="black" />
                        </TouchableOpacity>
                    </View>
                    <Text style={{ fontFamily: 'Regular', fontSize: width < 1024 ? 18 : 18, marginBottom: 10 }} >{this.props.translate.translate ? '输入折扣金额' : 'Enter Discount Amount'}</Text>
                    <Text style={{ fontFamily: 'Regular', fontSize: width < 1024 ? 18 : 18, marginBottom: 10 }} >{this.props.translate.translate ? '税率' : 'Tax Rate'}: 8.00%</Text>
                    <View>
                        <Text style={[styles.testInputStyle]} >{this.state.discount}</Text>
                        <View style={{ flexDirection: 'row' }} >
                            <View style={{ width: width < 1024 ? 189 : 204, flexDirection: 'row', flexWrap: 'wrap', marginTop: 3 }} >
                                {
                                    this.number.map((item, index) => {
                                        return (
                                            <TouchableOpacity key={index} style={styles.numberStyle} onPress={() => this.discountKeyboard(item)} >
                                                <Text style={{ fontFamily: 'Regular', }}>{item}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </View>
                            <View style={{ marginTop: 3 }}>
                                <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.discountKeyboard('delete')} >
                                    <Feather name="delete" size={20} color="black" />
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.discountKeyboard('ok')} >
                                    <AntDesign name="check" size={20} color="black" />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
                {/* orderType 弹窗 */}
                <View style={[styles.modalStyle, { width: width < 1024 ? 420 : 500, display: this.state.OrderTypeVisible ? 'flex' : 'none', position: this.state.OrderTypeVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ alignItems: 'flex-end', width: '100%' }}>
                        <TouchableOpacity onPress={() => this.setState({ OrderTypeVisible: false, buttonDisable: false })} >
                            <AntDesign name="close" size={width < 1024 ? 18 : 23} color="black" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-around' }} >
                        {
                            this.props.orderTypeList && this.props.orderTypeList.map((item, index) => {
                                return (
                                    <TouchableOpacity style={[styles.orderType]} disabled={item.available ? false : true} key={index}
                                        // onPress={() => this.setState({ orderType: 'WALK_IN', OrderTypeVisible: false, buttonDisable: false })}
                                        onPress={() => this.changeOrderType(item.value)}
                                    >
                                        <View style={styles.iconStyle} >
                                            <Image source={item.imgUrl} style={{ width: width < 1024 ? 18 : 23, height: width < 1024 ? 18 : 23 }} />
                                        </View>
                                        <Text style={{ fontFamily: 'Regular', fontSize: width < 1024 ? 12 : 15, marginTop: 5 }}>{this.props.translate.translate ? item.cnName : item.name}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>
                </View>
                {/* tips弹窗 */}
                <View style={[styles.modalStyle, { display: this.state.TipsVisible ? 'flex' : 'none', position: this.state.TipsVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ alignItems: 'flex-end', width: '100%' }}>
                        <TouchableOpacity onPress={() => this.setState({ TipsVisible: false, buttonDisable: false })} >
                            <AntDesign name="close" size={width < 1024 ? 18 : 23} color="black" />
                        </TouchableOpacity>
                    </View>
                    <Text style={{ fontFamily: 'Regular', fontSize: width < 1024 ? 18 : 18, marginBottom: 10 }} >{this.props.translate.translate ? '输入小费金额' : 'Enter Tips Amount'}</Text>
                    <Text style={{ fontFamily: 'Regular', fontSize: width < 1024 ? 18 : 18, marginBottom: 10 }} >{this.props.translate.translate ? '税率' : 'Tax Rate'}: 8.00%</Text>
                    <View>
                        <View>
                            <Text style={[styles.testInputStyle]} >{this.state.tips}</Text>
                            <View style={{ flexDirection: 'row' }} >
                                <View style={{ width: width < 1024 ? 189 : 204, flexDirection: 'row', flexWrap: 'wrap', marginTop: 3 }} >
                                    {
                                        this.number.map((item, index) => {
                                            return (
                                                <TouchableOpacity key={index} style={styles.numberStyle} onPress={() => this.tipsKeyboard(item)} >
                                                    <Text style={{ fontFamily: 'Regular', }}>{item}</Text>
                                                </TouchableOpacity>
                                            )
                                        })
                                    }
                                </View>
                                <View style={{ marginTop: 3 }}>
                                    <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.tipsKeyboard('delete')}>
                                        <Feather name="delete" size={width < 1024 ? 18 : 23} color="black" />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.tipsKeyboard('ok')}>
                                        <AntDesign name="check" size={width < 1024 ? 18 : 23} color="black" />
                                    </TouchableOpacity>
                                </View>
                            </View>

                        </View>
                    </View>
                </View>
                {/* setPrice弹窗 */}
                <View style={[styles.modalStyle, { display: this.state.PriceVisible ? 'flex' : 'none', position: this.state.PriceVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ alignItems: 'flex-end', width: '100%' }}>
                        <TouchableOpacity onPress={() => this.setState({ PriceVisible: false, temPrice: '', buttonDisable: false })} >
                            <AntDesign name="close" size={width < 1024 ? 18 : 23} color="black" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: 15 }} >
                        <View>
                            <Text style={[styles.testInputStyle]} >{this.state.temPrice}</Text>
                            <View style={{ flexDirection: 'row' }} >
                                <View style={{ width: width < 1024 ? 189 : 204, flexDirection: 'row', flexWrap: 'wrap' }} >
                                    {
                                        this.number2.map((item, index) => {
                                            return (
                                                <TouchableOpacity key={index} style={styles.numberStyle} onPress={() => this.priceKeyboard(item)} >
                                                    <Text style={{ fontFamily: 'Regular', }}>{item}</Text>
                                                </TouchableOpacity>
                                            )
                                        })
                                    }
                                    <TouchableOpacity style={[styles.numberStyle, { width: width < 1024 ? 121 : 131 }]} onPress={() => this.priceKeyboard(0)} >
                                        <Text style={{ fontFamily: 'Regular', }}>0</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.numberStyle} onPress={() => this.priceKeyboard('.')}>
                                        <Text style={{ fontFamily: 'Regular', }}>.</Text>
                                    </TouchableOpacity>
                                </View>
                                <View>
                                    <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.priceKeyboard('delete')} >
                                        <Feather name="delete" size={width < 1024 ? 18 : 23} color="black" />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.priceKeyboard('ok')} >
                                        <AntDesign name="check" size={width < 1024 ? 18 : 23} color="black" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                {/* qty弹窗 */}
                <View style={[styles.modalStyle, { display: this.state.QtyVisible ? 'flex' : 'none', position: this.state.QtyVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ alignItems: 'flex-end', width: '100%' }}>
                        <TouchableOpacity onPress={() => this.setState({ QtyVisible: false, buttonDisable: false })} >
                            <AntDesign name="close" size={width < 1024 ? 18 : 23} color="black" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: 15 }} >
                        <View>
                            <Text style={[styles.testInputStyle]} >{this.state.temQty}</Text>
                            <View style={{ flexDirection: 'row' }} >
                                <View style={{ width: width < 1024 ? 189 : 204, flexDirection: 'row', flexWrap: 'wrap' }} >
                                    {
                                        this.number2.map((item, index) => {
                                            return (
                                                <TouchableOpacity key={index} style={styles.numberStyle} onPress={() => this.qtyKeyboard(item)} >
                                                    <Text style={{ fontFamily: 'Regular', }}>{item}</Text>
                                                </TouchableOpacity>
                                            )
                                        })
                                    }
                                    <TouchableOpacity style={[styles.numberStyle, { width: width < 1024 ? 121 : 131 }]} onPress={() => this.qtyKeyboard('0')}>
                                        <Text style={{ fontFamily: 'Regular', }}>0</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.numberStyle} onPress={() => this.qtyKeyboard('.')}>
                                        <Text style={{ fontFamily: 'Regular', }}>.</Text>
                                    </TouchableOpacity>
                                </View>
                                <View>
                                    <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.qtyKeyboard('delete')}>
                                        <Feather name="delete" size={width < 1024 ? 18 : 23} color="black" />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.numberStyle, { height: width < 1024 ? 121 : 134 }]} onPress={() => this.qtyKeyboard('ok')}>
                                        <AntDesign name="check" size={width < 1024 ? 18 : 23} color="black" />
                                    </TouchableOpacity>
                                </View>
                            </View>

                        </View>
                    </View>
                </View>
                {/* schedule time 弹窗 */}
                <View style={[styles.timeModalStyle, { display: this.state.TimeVisible ? 'flex' : 'none', position: this.state.TimeVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ padding: 10, borderWidth: 1, width: 350, borderColor: 'rgb(153,153,153)' }} >
                        <View>
                            <View style={{ backgroundColor: '#fff', padding: 5 }} >
                                <CalendarPicker
                                    onDateChange={(date) => this.setState({ scheduleTime: Moment(date).format('YYYY/MM/DD') })}
                                    width={350}
                                    minDate={new Date()}
                                    selectedDayColor={'rgb(0,173,245)'}
                                    selectedDayTextColor={'#fff'}
                                    previousComponent={<AntDesign name="left" size={20} color="rgb(0,173,245)" />}
                                    nextComponent={<AntDesign name="right" size={20} color="rgb(0,173,245)" />}

                                />
                            </View>
                            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 15 }}>
                                <TouchableWithoutFeedback onPress={() => this.setState({ HourDropActive: !this.state.HourDropActive })}>
                                    <View style={{ borderColor: 'rgb(153,153,153)', borderWidth: 1, width: 100, backgroundColor: '#fff' }} >
                                        <Text style={{ width: 80, paddingVertical: 3, fontFamily: 'Regular', textAlign: 'center', fontSize: 16, height: 25 }} >{this.state.selectHour}</Text>
                                        <View style={{ position: 'absolute', top: 1, right: 3 }}>
                                            <Entypo name={!this.state.HourDropActive ? "chevron-thin-down" : 'chevron-thin-up'} size={18} color="rgb(180,180,180)" />
                                        </View>
                                    </View>
                                </TouchableWithoutFeedback>
                                <View><Text>:</Text></View>
                                <TouchableWithoutFeedback onPress={() => this.setState({ MinuteDropActive: !this.state.MinuteDropActive })}>
                                    <View style={{ borderColor: 'rgb(153,153,153)', borderWidth: 1, width: 100, backgroundColor: '#fff' }} >
                                        <Text style={{ width: 80, paddingVertical: 3, fontFamily: 'Regular', textAlign: 'center', fontSize: 16, height: 25 }} >{this.state.selectMinute}</Text>
                                        <View style={{ position: 'absolute', top: 1, right: 3 }}>
                                            <Entypo name={!this.state.MinuteDropActive ? "chevron-thin-down" : 'chevron-thin-up'} size={18} color="rgb(180,180,180)" />
                                        </View>
                                    </View>
                                </TouchableWithoutFeedback>
                            </View>
                        </View>
                    </View>
                    <View style={{ justifyContent: 'space-between', padding: 10 }} >
                        <View>
                            <TouchableOpacity style={[styles.blockStyle, { width: 60, height: 60, backgroundColor: '#99ccff' }]} onPress={() => {
                                this.setState({
                                    scheduleTime: this.state.scheduleTime === '' ? Moment().format('YYYY/MM/DD') : this.state.scheduleTime,
                                    selectHour: Moment().format('HH'),
                                    selectMinute: Moment().format('mm')
                                })
                            }}  >
                                <AntDesign name="calendar" size={20} color="black" />
                                <Text style={{ fontFamily: 'Regular', fontSize: 12 }} >{this.props.translate.translate ? '现在' : 'Now'}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.blockStyle, { width: 60, height: 60, backgroundColor: '#99ccff' }]} onPress={() => {
                                this.setState({
                                    selectHour: '',
                                    selectMinute: '',
                                    scheduleTime: ''
                                })
                            }}>
                                <AntDesign name="delete" size={20} color="black" />
                                <Text style={{ fontFamily: 'Regular', fontSize: 12 }} >{this.props.translate.translate ? '清除' : 'Clear'}</Text>
                            </TouchableOpacity>
                        </View>
                        <View>
                            <TouchableOpacity style={[styles.blockStyle, { width: 60, height: 60, backgroundColor: '#99ccff' }]} onPress={() => {
                                this.setState({
                                    TimeVisible: false,
                                    buttonDisable: false,
                                    finnalScheduleTime: this.state.scheduleTime === '' ? '' : this.state.scheduleTime + ' ' + this.state.selectHour + ':' + this.state.selectMinute + ':00',
                                })
                            }}
                            >
                                <AntDesign name="save" size={20} color="black" />
                                <Text style={{ fontFamily: 'Regular', fontSize: 12 }} >{this.props.translate.translate ? '保存' : 'Save'}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.blockStyle, { width: 60, height: 60, backgroundColor: '#ff9999' }]} onPress={() => this.setState({ TimeVisible: false, buttonDisable: false })}>
                                <MaterialIcons name="exit-to-app" size={20} color="black" />
                                <Text style={{ fontFamily: 'Regular', fontSize: 12 }} >{this.props.translate.translate ? '退出' : 'Exit'}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ backgroundColor: '#fff', width: 100, height: 130, bottom: -109, left: 52, position: this.state.HourDropActive ? 'absolute' : 'relative', display: this.state.HourDropActive ? 'flex' : 'none' }} >
                        <ScrollView>
                            {
                                this.hours.filter(p => { return p >= parseInt(Moment().format('H')) }).map((item, index) => {
                                    return (
                                        <TouchableOpacity style={{ marginVertical: 3, marginLeft: 3 }} key={index} onPress={() => { this.setState({ HourDropActive: false, selectHour: item }) }} >
                                            <Text>{item}</Text>
                                        </TouchableOpacity>
                                    )
                                })

                            }
                        </ScrollView>
                    </View>
                    <View style={{ backgroundColor: '#fff', width: 100, height: 130, bottom: -109, right: 121, position: this.state.MinuteDropActive ? 'absolute' : 'relative', display: this.state.MinuteDropActive ? 'flex' : 'none' }} >
                        <ScrollView>
                            {
                                this.shouwMinutes()

                            }
                        </ScrollView>
                    </View>
                </View>
                {/* self input 弹窗 */}
                <View style={[styles.selfModalStyle, { display: this.state.SelfVisible ? 'flex' : 'none', position: this.state.SelfVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ flexDirection: 'row', paddingLeft: 10, paddingTop: 10 }} >
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Regular', fontSize: 15, marginTop: 8 }} >{this.props.translate.translate ? '名字' : 'Name'}:</Text>
                            <TouchableWithoutFeedback onPress={() => {
                                this.setState({
                                    inputQuantityActive: false,
                                    inputPriceActive: false,
                                    inputSelect: 'InName',
                                    inputNameActive: true
                                })
                            }}>
                                <View>
                                    <Text style={[styles.testInputStyle, { height: 35, fontSize: 15, marginLeft: 5, lineHeight: 35 }, this.state.inputNameActive ? { borderColor: 'rgb(229,151,0)', borderWidth: 2 } : { borderColor: 'white', borderWidth: 2 }]}>
                                        {this.state.selfInputData.name}
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>
                        <View style={{ flexDirection: 'row', marginLeft: 50 }} >
                            <Text style={{ fontFamily: 'Regular', fontSize: 15, marginTop: 8 }} >{this.props.translate.translate ? '打印至' : 'Print To'}:</Text>
                            <View style={{ flexDirection: 'row' }} >
                                <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => this.setState({ selfInputData: { ...this.state.selfInputData, Tkitchen: !this.state.selfInputData.Tkitchen } })} >
                                    {
                                        this.state.selfInputData.Tkitchen ? <MaterialIcons name="check-box" size={20} color="rgb(65,148,136)" /> : <MaterialIcons name="check-box-outline-blank" size={20} color="rgb(153,153,153)" />
                                    }
                                    <Text style={{ fontFamily: 'Regular', fontSize: 13 }} >Tkitchen</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 10 }} onPress={() => this.setState({ selfInputData: { ...this.state.selfInputData, TReceipt: !this.state.selfInputData.TReceipt } })} >
                                    {
                                        this.state.selfInputData.TReceipt ? <MaterialIcons name="check-box" size={20} color="rgb(65,148,136)" /> : <MaterialIcons name="check-box-outline-blank" size={20} color="rgb(153,153,153)" />
                                    }
                                    <Text style={{ fontFamily: 'Regular', fontSize: 13 }} >TReceipt</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => this.setState({ selfInputData: { ...this.state.selfInputData, TSushi: !this.state.selfInputData.TSushi } })} >
                                    {
                                        this.state.selfInputData.TSushi ? <MaterialIcons name="check-box" size={20} color="rgb(65,148,136)" /> : <MaterialIcons name="check-box-outline-blank" size={20} color="rgb(153,153,153)" />
                                    }
                                    <Text style={{ fontFamily: 'Regular', fontSize: 13 }} >TSushi</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', paddingLeft: 10, marginTop: 10 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Regular', fontSize: 15, marginTop: 8 }} >{this.props.translate.translate ? '单价' : 'Price'}:</Text>
                            <TouchableWithoutFeedback onPress={() => {
                                this.setState({
                                    inputQuantityActive: false,
                                    inputPriceActive: true,
                                    inputSelect: 'InPrice',
                                    inputNameActive: false
                                })
                            }}>
                                <View>
                                    <Text style={[styles.testInputStyle, { height: 35, marginLeft: 11, width: 175, fontSize: 15, lineHeight: 35 }, this.state.inputPriceActive ? { borderColor: 'rgb(229,151,0)', borderWidth: 2 } : { borderColor: 'white', borderWidth: 2 }]} >
                                        {this.state.selfInputData.price}
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                            <TouchableOpacity style={{ width: 70, height: 35, backgroundColor: '#FFCC99', borderRadius: 5, justifyContent: 'center', alignItems: 'center', marginHorizontal: 10 }}
                                onPress={() => this.setState({ selfInputData: { ...this.state.selfInputData, price: '1.00' } })}
                            >
                                <Text style={{ fontFamily: 'Regular', fontSize: 14 }} >1.00</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ width: 70, height: 35, backgroundColor: '#FFCC99', borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}
                                onPress={() => this.setState({ selfInputData: { ...this.state.selfInputData, price: '2.00' } })}
                            >
                                <Text style={{ fontFamily: 'Regular', fontSize: 14 }} >2.00</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', paddingLeft: 10, marginTop: 10 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Regular', fontSize: 15, marginTop: 8 }} >{this.props.translate.translate ? '数量' : 'Quantity'}:</Text>
                            <TouchableWithoutFeedback onPress={() => {
                                this.setState({
                                    inputQuantityActive: true,
                                    inputSelect: 'InQuantity',
                                    inputNameActive: false,
                                    inputPriceActive: false
                                })
                            }}>
                                <View>
                                    <Text style={[styles.testInputStyle, { height: 35, marginLeft: 11, lineHeight: 35, width: 150, fontSize: 15, borderWidth: 2, borderColor: this.state.inputQuantityActive ? 'rgb(229,151,0)' : 'white' }]} >
                                        {this.state.selfInputData.quantity}
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                            <TouchableOpacity style={{ width: 70, height: 35, backgroundColor: '#FFCC99', borderRadius: 5, justifyContent: 'center', alignItems: 'center', marginHorizontal: 10 }}
                                onPress={() => this.setState({ selfInputData: { ...this.state.selfInputData, price: '3.00' } })}
                            >
                                <Text style={{ fontFamily: 'Regular', fontSize: 14 }} >3.00</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ width: 70, height: 35, backgroundColor: '#FFCC99', borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}
                                onPress={() => this.setState({ selfInputData: { ...this.state.selfInputData, price: '4.00' } })}
                            >
                                <Text style={{ fontFamily: 'Regular', fontSize: 14 }} >4.00</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <Board changeVaule={this.changeVaule} select={this.state.inputSelect} />
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }} >
                        <TouchableOpacity style={[styles.numberStyle, { width: 65, height: 65 }]} onPress={this.selfInputDataSave} >
                            <AntDesign name='check' size={20} color='block' />
                            <Text style={{ fontFamily: 'Regular', fontSize: 13 }} >{this.props.translate.translate ? '确定' : 'OK'}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.numberStyle, { width: 65, height: 65 }]} onPress={() => this.setState({ SelfVisible: false, buttonDisable: false })}>
                            <AntDesign name='close' size={20} color='block' />
                            <Text style={{ fontFamily: 'Regular', fontSize: 13 }} >{this.props.translate.translate ? '取消' : 'Cancel'}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {/* 分单确认 */}
                <View style={[styles.modalStyle, { width: 450, display: this.state.SuresplickVisible ? 'flex' : 'none', position: this.state.SuresplickVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ alignItems: 'flex-end', width: '100%' }} >
                        <TouchableOpacity onPress={() => this.setState({ SuresplickVisible: false, buttonDisable: false })} >
                            <AntDesign name="close" size={width < 1024 ? 18 : 23} color="black" />
                        </TouchableOpacity>
                    </View>
                    <View>
                        <Text style={[styles.MeFont, { fontSize: width < 1024 ? 14 : 16 }]} >Would you want to save your order and split the check?</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 18 }} >
                        <TouchableOpacity style={{ width: 60, height: 30, backgroundColor: 'rgb(153, 204, 255)', alignItems: 'center', justifyContent: 'center', borderRadius: 3 }} onPress={this.gotoSplit} >
                            <Text style={[styles.ReFont]} >Yes {this.state.sureLoading ? <ActivityIndicator size={'small'} /> : ''}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ width: 60, height: 30, backgroundColor: 'rgb(153, 204, 255)', alignItems: 'center', justifyContent: 'center', borderRadius: 3 }} onPress={() => this.setState({ SuresplickVisible: false, buttonDisable: false })} >
                            <Text style={styles.ReFont} >No</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {/* 付款确认 */}
                <View style={[styles.modalStyle, { width: 450, display: this.state.SurePayVisible ? 'flex' : 'none', position: this.state.SurePayVisible ? 'absolute' : 'relative' }]} >
                    <View style={{ alignItems: 'flex-end', width: '100%' }} >
                        <TouchableOpacity onPress={() => this.setState({ SurePayVisible: false, buttonDisable: false })} >
                            <AntDesign name="close" size={width < 1024 ? 18 : 23} color="black" />
                        </TouchableOpacity>
                    </View>
                    <View>
                        <Text style={[styles.MeFont, { fontSize: width < 1024 ? 14 : 16 }]} >Would you want to save your order and pay the order?</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 18 }} >
                        <TouchableOpacity style={{ width: 60, height: 30, backgroundColor: 'rgb(153, 204, 255)', alignItems: 'center', justifyContent: 'center', borderRadius: 3 }} onPress={this.gotoPaymeny} >
                            <Text style={[styles.ReFont]} >Yes {this.state.sureLoading ? <ActivityIndicator size={'small'} /> : ''}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ width: 60, height: 30, backgroundColor: 'rgb(153, 204, 255)', alignItems: 'center', justifyContent: 'center', borderRadius: 3 }} onPress={() => this.setState({ SurePayVisible: false, buttonDisable: false })} >
                            <Text style={styles.ReFont} >No</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            // </ScrollView>
        )
    }
}

const { width, height } = Dimensions.get('window')

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        // marginTop:100
    },
    blockStyle: {
        backgroundColor: '#FFCC99',
        width: width < 1024 ? 70 : 76.8,
        height: width < 1024 ? 70 : 71.67,
        borderRadius: 5,
        margin: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    otherType: {
        backgroundColor: '#FFCC99',
        width: width < 1024 ? 69 : 77,
        height: width < 1024 ? 62 : 71.68,
        maxWidth: 90,
        maxHeight: 85,
        borderRadius: 5,
        margin: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    active: {
        backgroundColor: '#CCCCFF'
    },
    MeFont: {
        fontFamily: 'TTFMedium',
        fontSize: width < 1024 ? 13 : 13.5,
        textAlign: 'center'
    },
    ReFont: {
        fontFamily: 'Regular',
        fontSize: width < 1024 ? 13 : 13.5,
        textAlign: 'center'
    },
    smallBlockStyle: {
        width: width < 1024 ? 65 : 70,
        height: width < 1024 ? 25 : 30,
        borderRadius: 5,
        borderColor: '#CCCCCC',
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginLeft: 10,
        paddingRight: 5
    },
    upDownStyle: {
        width: width < 1024 ? 65 : 71,
        height: width < 1024 ? 85 : 100,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#99CCFF'
    },
    modalStyle: {
        width: width < 1024 ? 270 : 289,
        backgroundColor: 'rgb(236,236,236)',
        position: 'absolute',
        padding: 10,
        borderRadius: 3,
    },
    testInputStyle: {
        width: width < 1024 ? 247 : 266,
        height: width < 1024 ? 40 : 40,
        lineHeight: width < 1024 ? 40 : 40,
        backgroundColor: "#fff",
        borderRadius: 3,
        fontFamily: 'Regular',
        fontSize: width < 1024 ? 15 : 15,
        paddingLeft: 5,
        // paddingTop: width < 1024 ? 12 : 15,
    },
    numberStyle: {
        width: width < 1024 ? 58 : 63,
        height: width < 1024 ? 58 : 63,
        backgroundColor: '#99CCFF',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        marginTop: 5,
        marginRight: 5
    },
    listStyle: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#99CCCC'
    },
    orderType: {
        backgroundColor: '#99CCFF',
        width: width < 1024 ? 60 : 70,
        height: width < 1024 ? 60 : 70,
        borderRadius: 10,
        margin: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconStyle: {
        alignItems: 'center'
    },
    timeModalStyle: {
        width: 450,
        backgroundColor: 'rgb(236,236,236)',
        position: 'absolute',
        padding: 10,
        borderRadius: 3,
        borderWidth: 5,
        borderColor: 'rgb(200,200,200)',
        flexDirection: 'row'
    },
    selfModalStyle: {
        width: width < 1024 ? 700 : 700,
        backgroundColor: 'rgb(236,236,236)',
        // position: 'absolute',
        padding: 10,
        borderRadius: 3,
    },
    dishNameStyle: {
        backgroundColor: '#99CCFF',
        width: width < 1024 ? 82 : 89,
        height: width < 1024 ? 70 : 71.68,
        borderRadius: 5,
        margin: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    flavorItemStyle: {
        backgroundColor: '#FFCC99',
        width: width < 1024 ? 70 : 71.68,
        height: width < 1024 ? 63 : 71.68,
        borderRadius: 5,
        margin: 1,
        justifyContent: 'center'
    }
})

export default connect(
    state => ({
        translate: state.translate,
        allMnuList: state.menu,
        employee: state.employee,
        orderTypeList: state.orderTypeList
        // restaurant: state.auth.restaurantInfos[0]
    }),
    {

    }
)(Test)